import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import NoSSR from "react-no-ssr"
import BackgroundSlider from "react-background-slider"
import PageWrapper from "../../layouts/PageWrapper"
import AppImg from "./applicationForm.png"
import AppPDF from "./officestaff.pdf"
// import CrewDoc from './crew.docx'
import CrewPDF from "./crew.pdf"
import CareerImg from "./career.jpg"
import HomeLogo from "../../components/HomeLogo"

export default class CareerPage extends Component {
  render() {
    return (
      <PageWrapper>
        <section className="hero is-fullheight">
          <div>
            <NoSSR>
              <BackgroundSlider
                images={[CareerImg]}
                duration={10000}
                transition={0}
              />
            </NoSSR>
            <Helmet>
              <title>Career - EA Technique</title>
            </Helmet>
            <section className="hero is-small">
              <div className="hero-body">
                <div className="container">
                  <div className="columns">
                    <div className="column">
                      <HomeLogo />
                      <h1 className="title" id="title-line">
                        Career Opportunity
                      </h1>
                      <div className="section">
                        <h1
                          className="title has-text-centered"
                          style={{ marginTop: "30px" }}
                        >
                          If you are interested to join us for any related post,{" "}
                          <br />
                          email us your full resume with photo to
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="columns ">
                <div
                  className="column is-3 is-offset-2 has-text-centered"
                  id="career-font"
                  style={{
                    background: `#026CEB`,
                    outline: `1px solid #fff`,
                    outlineOffset: `-10px`,
                  }}
                >
                  <br />
                  <h1 className="title is-4">Office Staff</h1>
                  <p className="is-size-7">HR Department</p>
                  <br />
                  <p className="is-size-7">
                    Email : recruitment@eatechnique.com.my
                  </p>
                  <p className="is-size-7">
                    Click here to download the application form
                  </p>
                  <br />
                  <br />
                  <a href={AppPDF} download>
                    <img
                      src={AppImg}
                      style={{
                        height: `70px`,
                        width: `70px`,
                      }}
                    />
                    <p>Application Form</p>
                  </a>
                  <br />
                </div>
                <div className="column is-1" />

                <div
                  className="column is-offset-1 has-text-centered"
                  id="career-font"
                  style={{
                    background: `#F6921E`,
                    outline: `1px solid #fff`,
                    outlineOffset: `-10px`,
                  }}
                >
                  <br />
                  <h1 className="title is-4">Crew</h1>
                  <p className="is-size-7">HR Department</p>
                  <br />
                  <p className="is-size-7">
                    Email : recruitment@eatechnique.com.my
                  </p>
                  <p className="is-size-7">
                    Click here to download the application form
                  </p>
                  <br />
                  <br />
                  <a href={CrewPDF} download>
                    <img
                      src={AppImg}
                      style={{
                        height: `70px`,
                        width: `70px`,
                      }}
                    />
                    <p>Application Form</p>
                  </a>
                  <br />
                </div>
                <div className="column is-2" />
              </div>
            </section>
          </div>
        </section>
      </PageWrapper>
    )
  }
}

CareerPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}
